body,
html {
  min-height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
  color: #444;
}

body {
  margin: 0;
  padding: 0;
  font-family: HelveticaNeue;
}

nav {
  padding: 10px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: space-around;
  border-bottom: solid 2px #80ae13;
}

main {
  margin-top: 80px;
  flex: 1;
  height: 100%;
}

footer {
  font-size: .8rem;
  padding: 10px;
  text-align: center;
  background-color: #f1f1f1;
}

/* GENERAL */

.fullscreen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.btn-primary {
  background-color: #80ae13;
  border-color: #80ae13;
  margin-right: 16px;
}

.btn-link,
.btn-link:hover,
.btn-link:visited {
  color: #80ae13;
}

.page-container {
  max-width: 700px;
  margin: 0 auto;
  padding-bottom: 100px;
}

h1,
h5 {
  font-weight: bold;
}

.card {
  margin-left: -20px;
  margin-right: -20px;
}

.premium .currency {
  font-size: 24px;
}

.premium .amount {
  font-size: 32px;
}

.policy-number {
  font-size: 64px;
  font-weight: bold;
}

.quote-option {
  margin: 4px;
  box-sizing: border-box;
  padding: 0 16px;
  border: solid 2px white;
  border-radius: 4px;
  cursor: pointer;
}
.quote-option:hover{
  border: solid 2px #80ae13;
}

.quote-option-active {
  margin: 4px;
  box-sizing: border-box;
  padding: 0 16px;
  border: solid 2px white;
  border-radius: 4px;
  cursor: pointer;
}
.quote-option-active {
  border: solid 2px #80ae13;
}
/* LANDING PAGE */

main.landing-page-cover {
  margin-top: 0;
  background-image: url('/landing-cover.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: scroll;
  padding: 64px 0;
}

#section-landing-page-cover {
  color: white;
  text-align: center;
  padding: 0 10% 64px 10%;
  box-sizing: border-box;
}
#section-landing-page-cover h3 {
  font-weight: lighter;
}

/* PROGRESS BAR */

.progress-step-container {
  flex: 1;
  text-align: center;
}

.progress-step-container:not(.done) .progress-step .progress-dot,
.progress-step-container:not(.done) .progress-step .progress-line {
  background-color: #dddddd;
}

.progress-step-container:not(.done) .progress-step .progress-dot {
  border: 2px solid #dddddd;
  background-color: white;
  z-index: 10;
  position: relative;
}

.progress-step-container.done .progress-step .progress-dot::before {
  content: ' ';
  display: block;
  background-image: url("/icon-16-tick-white.svg");
  background-size: 60%;
  background-position: 5px 5px;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.progress-step-container:last-child .progress-step .progress-line {
  background-color: transparent;
}

.progress-step-container.active .progress-step .progress-dot::before {
  content: ' ';
  display: block;
  width: 8px;
  height: 8px;
  background: #80ae13;
  border-radius: 50%;
  position: absolute;
  left: calc((100% - 8px) / 2);
  top: calc((100% - 8px) / 2);
}

.progress-step-container.active .progress-step .progress-dot {
  box-shadow: 0 0 10px 0 #c3c3c3;
  border: 0;
}

.progress-step {
  position: relative;
  height: 24px;
}

.progress-dot {
  position: absolute;
  left: calc(50% - 12px);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #80ae13;
}

.progress-line {
  position: absolute;
  left: 50%;
  right: 0;
  width: 100%;
  background-color: #80ae13;
  height: 2px;
  top: 11px;
  z-index: -1;
}

.progress-name {
  margin-top: 10px;
  font-size: 14px;
  color: #777777;
}

/* Full screen loader */
.full-screen-loader {
  background-color: #80ae13;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  color: white;
  text-align: center;

  /* Starting state */
  display: none;
}

.full-screen-loader.active {
  display: flex;
  position: fixed;
}

.full-screen-loader .line {
  background-color: white;
  height: 4px;
  width: 100%;
  margin-top: 28px;
}

.full-screen-loader .line .loader {
  background-color: white;
  height: 14px;
  border: 1px solid white;
  border-radius: 7px;
  top: -5px;
  position: relative;
  -webkit-transition: width .3s;
  /* Safari */
  transition: width .3s;

  width: 0;
  /* width: 50%; */
}

/* Signature */
canvas {
  background-color: #f1f1f1;
  display: block;
}

.signature-canvas {
  border: 2px solid #cccccc;
  border-radius: .25rem;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: none !important;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus{
  background-color: #719b11 !important;
  border-color: #719b11;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #99c929;
  border-color: #99c929;
}

#footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 10px;
  background-color: white;
  justify-content: space-around;
  border-top: solid 2px #80ae13;
}

.modal-dialog {
  max-width: 60% !important;
}

.shrink {
  max-width: 500px !important;
}

.infoList > li {
  margin: 8px;
}

.readMoreContent > p {
  padding: 0 32px;
}

.readMoreContent > p > h2 {
  font-size: 1.3em;
  font-weight: bold;
  color: #80ae13
}
